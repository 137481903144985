import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Col, Grid } from '@playbooks/interface/grid';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { Menu, MenuBlock, MenuItem, MenuLink, MenuList, MenuMenu, MenuTitle } from '@playbooks/interface/menus';
import { useApp, useStore, useToast } from 'contexts';

const BrowseMenu = ({ open, setOpen }) => {
	const { taxonomy } = useApp();
	const router = useRouter();
	const store = useStore();
	const toast = useToast();

	// Hooks
	useEffect(() => {
		return () => setOpen(false);
	}, []);

	useEffect(() => {
		setOpen(false);
	}, [router.asPath]);

	// Methods
	const onClose = () => setOpen(false);

	// Render
	return (
		<Menu open={open} onClose={onClose} zIndex='z-20'>
			<MenuMenu open={open} spacing='pb-[75px] sm:pb-0'>
				<MenuBlock border='border-b' height='min-h-[100px]'>
					<Grid gap='gap-x-8 gap-y-4'>
						<Col sm='6' lg='3'>
							<MenuList>
								<MenuItem>
									<MenuTitle>Languages</MenuTitle>
								</MenuItem>
								{taxonomy.languages?.slice(0, 5).map((v, i) => (
									<MenuItem key={i}>
										<MenuLink prevIcon={{ src: v.thumbnail }} href={`/languages/${v.uuid}`}>
											{v.name}
										</MenuLink>
									</MenuItem>
								))}
								<MenuItem>
									<MenuLink nextIcon='chevron-right' href='/languages'>
										View All
									</MenuLink>
								</MenuItem>
							</MenuList>
						</Col>

						<Col sm='6' lg='3'>
							<MenuList>
								<MenuItem>
									<MenuTitle>Frameworks</MenuTitle>
								</MenuItem>
								{taxonomy.frameworks?.slice(0, 5).map((v, i) => (
									<MenuItem key={i}>
										<MenuLink prevIcon={{ src: v.thumbnail }} href={`/frameworks/${v.uuid}`}>
											{v.name}
										</MenuLink>
									</MenuItem>
								))}
								<MenuItem>
									<MenuLink nextIcon='chevron-right' href='/frameworks'>
										View All
									</MenuLink>
								</MenuItem>
							</MenuList>
						</Col>

						<Col sm='6' lg='3'>
							<MenuList>
								<MenuItem>
									<MenuTitle>Tools</MenuTitle>
								</MenuItem>
								{taxonomy.tools?.slice(0, 5).map((v, i) => (
									<MenuItem key={i}>
										<MenuLink prevIcon={{ src: v.thumbnail }} href={`/tools/${v.uuid}`}>
											{v.name}
										</MenuLink>
									</MenuItem>
								))}
								<MenuItem>
									<MenuLink nextIcon='chevron-right' href='/tools'>
										View All
									</MenuLink>
								</MenuItem>
							</MenuList>
						</Col>

						<Col sm='6' lg='3'>
							<MenuList>
								<MenuItem>
									<MenuTitle>Topics</MenuTitle>
								</MenuItem>
								{taxonomy.topics?.slice(0, 5).map((v, i) => (
									<MenuItem key={i}>
										<MenuLink prevIcon='hashtag' href={`/topics/${v.uuid}`}>
											{v.name}
										</MenuLink>
									</MenuItem>
								))}
								<MenuItem>
									<MenuLink nextIcon='chevron-right' href='/topics'>
										View All
									</MenuLink>
								</MenuItem>
							</MenuList>
						</Col>
					</Grid>
				</MenuBlock>
				<MenuBlock>
					<Grid>
						<Col span='12' md='4' border='md:border-l' spacing='md:pl-4'>
							<MenuLink
								prevIcon={{ type: 'wrapped-fad', icon: 'code' }}
								href='/repos'
								span={{ display: 'flex-between', flex: 'grow' }}>
								<Span>Browse Repos</Span>
								<FarIcon icon='chevron-right' />
							</MenuLink>
						</Col>
						<Col span='12' md='4' border='lg:border-l' spacing='lg:pl-4'>
							<MenuLink
								prevIcon={{ type: 'wrapped-fad', icon: 'sack-dollar' }}
								href='/bounties'
								span={{ display: 'flex-between', flex: 'grow' }}>
								<Span>Browse Bounties</Span>
								<FarIcon icon='chevron-right' />
							</MenuLink>
						</Col>
						<Col span='12' md='4' border='lg:border-l' spacing='lg:pl-4'>
							<MenuLink
								prevIcon={{ type: 'wrapped-fad', icon: 'layer-group' }}
								href='/collections'
								span={{ display: 'flex-between', flex: 'grow' }}>
								<Span>Browse Collections</Span>
								<FarIcon icon='chevron-right' />
							</MenuLink>
						</Col>
					</Grid>
				</MenuBlock>
			</MenuMenu>
		</Menu>
	);
};

export { BrowseMenu };
