import { useEffect, useRef, useState } from 'react';

import { timeout } from 'utils/helpers';

export const useDelay = (method, timing) => {
	const [count, setCount] = useState(0);
	const [active, setActive] = useState(false);

	useEffect(() => {
		const init = async () => {
			await timeout(timing);
			method();
		};
		init();
	}, []);
};

export const useFocus = (listeners = []) => {
	const inputRef = useRef(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, [...listeners]);

	return inputRef;
};

export const useInterval = (method, listeners = []) => {
	const [count, setCount] = useState(0);

	useEffect(() => {
		const init = async () => {
			try {
				method();
				await timeout(listeners[0]);
				if (count <= listeners[1]) setCount(count + 1);
			} catch (e) {
				setCount(listeners[1]);
			}
		};
		init();
	}, [count, ...listeners]);
};
