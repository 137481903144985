import { useState } from 'react';

import { useToast } from 'contexts';

export const useQuery = (method): [method: any, loading: boolean] => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();

	// Methods
	const onQuery = async (data?) => {
		try {
			setLoading(true);
			await method(data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	return [onQuery, loading];
};

export const useAction = (model, method): [method: any, taskRunning: any] => {
	const [taskRunning, setTaskRunning] = useState({ id: '', running: false });
	const toast = useToast();

	// Methods
	const onAction = async (record?) => {
		try {
			setTaskRunning({ id: model.id ? model.id : record?.id, running: true });
			await method(record);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning({ id: null, running: false });
		}
	};

	return [onAction, taskRunning];
};

export const useSave = (model, modelName, method): [method: any, taskRunning: any] => {
	const [taskRunning, setTaskRunning] = useState({ id: '', running: false });
	const toast = useToast();

	// Methods
	const onSave = async (record?) => {
		try {
			setTaskRunning({ id: model.id ? model.id : record?.id, running: true });
			await method(record);
			toast.showSuccess(200, `${modelName} saved!`);
		} catch (e) {
			toast.showError(e);
			console.log(e);
		} finally {
			setTaskRunning({ id: null, running: false });
		}
	};

	return [onSave, taskRunning];
};

export const useArchive = (model, modelName, method): [method: any, taskRunning: any] => {
	const [taskRunning, setTaskRunning] = useState({ id: '', running: false });
	const toast = useToast();

	// Methods
	const onArchive = async (record?) => {
		try {
			setTaskRunning({ id: model.id ? model.id : record?.id, running: true });
			const confirmed = window.confirm(`Are you sure you want to archive this ${modelName}?`);
			if (!confirmed) return;
			await method(record);
			toast.showSuccess(200, `${modelName} archived!`);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning({ id: null, running: false });
		}
	};

	return [onArchive, taskRunning];
};

export const useDelete = (model, modelName, method): [method: any, taskRunning: any] => {
	const [taskRunning, setTaskRunning] = useState({ id: '', running: false });
	const toast = useToast();

	// Methods
	const onDelete = async (record?) => {
		try {
			setTaskRunning({ id: model.id ? model.id : record?.id, running: true });
			const confirmed = window.confirm(`Are you sure you want to delete this ${modelName}?`);
			if (!confirmed) return;
			await method(record);
			toast.showSuccess(200, `${modelName} deleted!`);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning({ id: null, running: false });
		}
	};

	return [onDelete, taskRunning];
};

export const useDeletes = (modelName, method): [method: any, taskRunning: any] => {
	const [taskRunning, setTaskRunning] = useState({ running: false });
	const toast = useToast();

	// Methods
	const onDelete = async (record?) => {
		try {
			setTaskRunning({ running: true });
			const confirmed = window.confirm(`Are you sure you want to delete these ${modelName}?`);
			if (!confirmed) return;
			await method(record);
			toast.showSuccess(200, `${modelName} deleted!`);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning({ running: false });
		}
	};

	return [onDelete, taskRunning];
};

// Usage
// const [onSave, saveTask] = useQuery(async () => {})
